import "./App.css";

import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useEffect, useRef, lazy, Suspense } from "react";
import { Hub } from "aws-amplify/utils";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { useSelector } from "react-redux";
import "@fontsource/inter";
import { MySnackbar } from "./Components/MySnackbar/MySnackbar";
import { useUser } from "./context/UserContext";

import { useAdmin } from "./context/AdminContext";
import { Helmet } from "react-helmet"
import { Backdrop, CircularProgress } from "@mui/material";

// ui component or pages
const Home = lazy(() => import("./Pages/Home/Home"));
const Navbar = lazy(() => import("./Components/Navbar/Navbar"));
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy/PrivacyPolicy"));
const ContactUs = lazy(() => import("./Pages/ContactUs/ContactUs"));
const ShippingAddress = lazy(() => import("./Pages/Order/ShippingAddress/ShippingAddress"));
const CreateYourCharacter = lazy(() => import("./Pages/CreateYourCharacter/CreateYourCharacter"));
const Bookcategories = lazy(() => import("./Pages/Bookcategories/Bookcategories"));
const SigninSignup = lazy(() => import("./Pages/SigninSignup/SigninSignup"));
const AdminLogin = lazy(() => import("./Admin/AdminLogin"));
const AdminDashboard = lazy(() => import("./Admin/AdminDashboard"));
const Profile = lazy(() => import("./Pages/Profile/Profile"));
const OurBooksMain = lazy(() => import("./Pages/OurBooksMain/OurBooksMain"));
const OurStory = lazy(() => import("./Pages/OurStory/OurStory"));
const Cart = lazy(() => import("./Pages/Cart/Cart"));
const ComingSoon = lazy(() => import("./Pages/ComingSoon/ComingSoon"));
const ComingSoonChristmas = lazy(() => import("./Pages/ComingSoonChristmas/ComingSoonChristmas"));
const VoucherBanner = lazy(() => import("./Components/VoucherBanner/VoucherBanner"));
const OrderConfirmPage = lazy(() => import("./Pages/OrderConfirmPage/OrderConfirmPage"));

function App() {
  const location = useLocation();

  const { uniqueId, toaststate, size, variant, color, message, iCon } =
    useSelector((state) => state.toastSliceInfo);

  const { fullName, user, setUser } = useUser();

  const containerRefscroll = useRef(null);

  useEffect(() => {
    if (containerRefscroll.current) {
      containerRefscroll.current.scrollTop = 0;
    }
  }, [location.pathname]);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const authUser = await getCurrentUser();
        const userData = await fetchAuthSession();

        const payloaddata = userData?.tokens?.idToken?.payload;
        const groups = payloaddata["cognito:groups"] || [];
        if (groups.includes("Admin")) {
          setUser(null);
        } else {
          setUser(authUser.userId);
        }
      } catch (error) {
        setUser(null);
      }
    };

    checkUser();

    // Subscribe to authentication events to automatically update user state
    Hub.listen("auth", ({ payload }) => {
      if (payload.event === "signIn") {
        checkUser();
      }
      if (payload.event === "signOut") {
        setUser(null);
      }
    });
  }, []);

  const { admindetails } = useAdmin();

  const shouldShowNavbar =
    location.pathname === "/dashboard/admin/login" ||
    location.pathname === "/admin-dashboard" ||
    location.pathname === "/order-confirm";

  return (
    <div className="App">
      <Helmet>
        <title>I Want To Be...</title>
        <meta name="description" content="Personalised Children's Story Books"/>
        <meta  name="keywords" content="Story,Story Books,Children's Story Books, ebooks"/>
      </Helmet>
      {/* {!shouldShowNavbar && <VoucherBanner />} */}
      {!shouldShowNavbar && (
        <div className="navbarmain">
          <Navbar fullName={fullName} />
        </div>
      )}
      <div
        className={`${
          !shouldShowNavbar ? "mainContentDiv" : "mainContentDivforadmin"
        }`}
        ref={containerRefscroll}
      >
        <Suspense fallback={<Backdrop open={true} style={{ zIndex: 1300 }}>
        <CircularProgress style={{ color: "#7ABDE3" }} />
      </Backdrop>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/shippingaddress" element={<ShippingAddress />} />
          <Route
            path="/createyourcharacter"
            element={<CreateYourCharacter />}
          />
          <Route
            path="/our-books/category/:category"
            element={<Bookcategories />}
          />

          {!user && !admindetails && (
            <Route path="/login" element={<SigninSignup />} />
          )}
          {user && <Route path="/login" element={<Navigate to="/" />} />}
          {user && (
            <Route path="/profile" element={<Profile fullName={fullName} />} />
          )}
          {!user && <Route path="/profile" element={<Navigate to="/" />} />}
          <Route path="/our-books" element={<OurBooksMain />} />
          <Route path="/our-story" element={<OurStory />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/coming-soon" element={<ComingSoon />} />

          <Route path="/coming-soon/Santa" element={<ComingSoonChristmas />} />

          <Route path="/order-confirm" element={<OrderConfirmPage />} />

          {!user && !admindetails && (
            <Route path="/dashboard/admin/login" element={<AdminLogin />} />
          )}

          {admindetails && !user && (
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
          )}
        </Routes>
        </Suspense>
        <MySnackbar
          key={uniqueId} // Ensure unique ID
          variant={variant}
          color={color}
          toaststate={toaststate}
          message={message}
          size={size}
          iCon={iCon}
        />
      </div>
    </div>
  );
}

export default App;
